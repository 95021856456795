import React from "react"

const Track = ({ children }) => {
  return (
    <div>
      <ul className="list-none mt-8">
        {children.props.children.map((item, index) => {
          var trackName = null
          var trackLink = null
          if (typeof item.props.children === "string") {
            // Track is still offline
            trackName = item.props.children
          } else {
            // Track is online and has a link
            trackName = item.props.children.props.children
            trackLink = item.props.children.props.href
          }

          if (trackLink != null) {
            return (
              <li
                key={index}
                className="flex flex-row items-center space-x-2 my-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-6 h-6 flex-none text-primary-500"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <a href={trackLink} className="text-primary-500">
                  {trackName}
                </a>
              </li>
            )
          } else {
            return (
              <li key={index} className="flex items-center space-x-2 my-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-6 h-6 flex-none"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>{trackName}</span>
              </li>
            )
          }
        })}
        <li className="flex items-center space-x-2 my-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-6 h-6 flex-none"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
              clipRule="evenodd"
            />
          </svg>
          <span>
            <em>More coming soon!</em>
          </span>
        </li>
      </ul>
    </div>
  )
}

export default Track
