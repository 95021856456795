import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import nightOwlLight from "prism-react-renderer/themes/nightOwlLight"

export const ShellSession = ({ codeString, language, ...props }) => {
  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language="shellsession"
      theme={nightOwlLight}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <div
          className={`${className} whitespace-pre font-code rounded-br rounded-bl mx-auto -mt-8 p-4 overflow-x-auto border-2 border-t-0	 border-gray-200 w-11/12 max-w-xs sm:max-w-lg lg:max-w-xl`}
          style={style}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })} className="text-sm">
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </div>
      )}
    </Highlight>
  )
}
