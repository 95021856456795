import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Code } from "./src/components/Code"
import { ShellSession } from "./src/components/ShellSession"
import { preToCodeBlock } from "mdx-utils"
import Link from "./src/components/Link"
import Track from "./src/components/Home/Track"
import Output from "./src/components/Output"
import Image from "./src/components/Image"

// components is its own object outside of render so that the references to
// components are stable
const components = {
  pre: preProps => {
    const props = preToCodeBlock(preProps)

    // If preProps is decorated with `output`, treat content as console output
    if (preProps.output) {
      return <ShellSession {...props} />
    }
    // if there's a codeString and some props, we passed the test
    else if (props) {
      return <Code {...props} />
    } else {
      // it's possible to have a pre without a code in it
      return <pre {...preProps} />
    }
  },
  a: Link,
  Track,
  Output,
  img: Image,
}
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
)
